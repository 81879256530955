@import "variables";

.admin {
    .main-content {
        .signDiv {
            .Collapsible {
                .Collapsible__contentOuter {
                    .wrapper {
                        input {
                            display: none;
                        }
                    }
                }
            }
        }
        .Collapsible {
            .Collapsible__contentOuter {
                .wrapper {
                    display: flex;

                    input {
                        display: block;
                        margin-right: 15px;
                        height: 25px;
                    }

                    .Collapsible {
                        flex-grow: 1;
                    }
                }
            }
        }
        .adminNav {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            align-items: center;

			@media only screen and (max-width: 850px) {
				justify-content: center;
				margin-bottom: 20px;
			}

            .adminButton {
                color: white;
                background-color: $green;
                border: none;
                max-width: 250px;
                height: 70px;
                padding: 0 10px;
                text-transform: none;
                margin: 20px;
                &:hover {
                    background-color: $blue;
                }
            }
			.signOut.admin {
				@media only screen and (max-width: 850px) {
					width: 100%;
					text-align: center;
				}
				button {
					background-color: transparent;
					border: none;
					font-weight: bold;
					color: $green;

					&:hover {
						color: $blue;
						cursor: pointer;
					}
				}
			}
        }
    }
}

.main-content {
    margin: 0 50px;

    @media only screen and (max-width: $sidebar-hide-width) {
        margin: 0;
    }

    .adminNav {
        display: none;
    }

	.orderedCont {
		margin: 0 20px;
	}

    .Collapsible {
        max-width: 1000px;
        h2,
        h3 {
            border-bottom: 2px dotted $blue;
            color: $blue;
            &:before {
                content: "\f067";
                font-family: $awesome;
                font-weight: bold;
                background-color: $blue;
                color: white;
                padding: 8px;
                font-size: 0.6em;
                margin-right: 10px;
            }

            &:hover {
                color: $green;
                cursor: pointer;
            }
        }

        .Collapsible__contentOuter {
            margin: 0 50px;
			@media only screen and (max-width: 850px) {
				margin: 0 10px;
			}

            .wrapper {
				margin-bottom: 20px;
                input {
                    display: none;
                }
            }

            .personOrder {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .nameDate {
                    margin-right: 20px;
                    h4 {
                        font-size: 20px;
                        margin: 0;
                    }

                    p {
                        font-size: 12px;
                        margin: 0;
                    }
                }
            }
        }
    }
}
