@import "variables";

.button {
    width: 100%;
    height: 50px;
    border-radius: 50px;
    margin-top: 0px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
    border: none;
    cursor: pointer;
    background-color: white;
    color: $blue;

    &:hover {
        color: $green;
    }
}
