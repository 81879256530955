@import "~antd/dist/antd.css";
@import "variables";

html,
body {
    margin: 0;
    padding: 0;
}

p,
h1,
h2,
h3,
h4,
label,
.swal-title,
.swal-text {
    font-family: $font-standard;
}

*:focus {
    outline: none;
}

#root {
    display: flex;
    align-items: stretch;
    position: relative;
}

.swal-button {
    background-color: $blue;

    &:active {
        background-color: white;
        color: $blue;
    }

    &:focus {
        outline: none;
    }
}

.ant-layout.main-content {
    width: 100%;
    min-height: 100%;
    background-color: white;
    margin-right: 0;
}

.ant-layout-sider-collapsed + .ant-layout.main-content {
    margin-left: 0;
}

.hidden {
    display: none;
}
