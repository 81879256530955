@import "variables";
.admin {
    .navbar {
        display: none;
    }
}
.navbar {
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .button {
        width: 40%;
        min-width: 150px;
        max-width: 180px;
        margin: 10px;
        color: $blue;
        background-color: white;
        border: 3px solid $blue;

        &.selected {
            color: white;
            background-color: $blue;
        }

        &:hover {
            color: $green;
            border: 3px solid $green;
        }

        &.adminButton {
            display: none;
        }
    }
}
