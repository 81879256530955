@import "variables";

.input {
    position: relative;
    background-color: white;
    height: 55px;
    margin-bottom: 20px;
    padding: 5px;
    padding-bottom: 0;
    color: black;

    label {
        margin: 0;
        color: $blue;
        font-weight: bold;
        text-transform: uppercase;
        font-style: normal;
        font-size: 0.8em;
    }

    input {
        background-color: transparent;
        border: none;
        border-radius: 5px;
        width: 100%;
        height: 20px;

        &:focus {
            outline: none;
        }
    }
}
