@import "variables";

.singleGallery {
    padding: 30px;
    box-sizing: border-box;
    position: relative;
    width: calc(100% / 3);
    max-width: 500px;
    margin-bottom: 50px;

    @media only screen and (max-width: 1500px) {
        width: calc(100% / 2);
    }

    @media only screen and (max-width: 1200px) {
        width: 100%;
    }

    .topGallery {
        margin: 0 20px;

        img {
            height: 230px;
            object-fit: contain;
        }

        .galleryHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 70px;

            h2,
            h3 {
                margin: 0;
                color: $blue;
            }
            h2 {
                text-transform: uppercase;
                font-weight: bold;
                font-size: 1.2em;
            }
            h3 {
                font-size: 1em;
            }

			p {
				color: $blue;
				font-style: italic;
				font-weight: 300;
				margin: 0;
			}

            .countContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin-left: 10px;

                p {
                    font-size: 1.4em;
                    font-weight: bold;
                    color: $blue;
                    margin: 0;

                    &:first-child {
                        font-size: 0.9em;
						font-style: normal;
                        margin-bottom: 5px;
                        border-bottom: 2px solid $blue;
                    }
                }
            }
        }

        img {
            width: 100%;
            margin: 10px 0px 20px;
        }
    }

    .bottomGallery {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $blue;
        height: 50px;
        border-radius: 50px;

        select {
            margin-right: 5px;
			background-color: white;
        }
        button {
            margin-left: 5px;
            background-color: white;
            color: $blue;
            border: none;
            text-transform: uppercase;
            font-weight: bold;
            height: 30px;
            border-radius: 15px;
            padding: 5px 10px;
            font-size: 12px;

            &:hover {
                color: $green;
            }
        }
        select,
        button {
            cursor: pointer;
        }
    }
}
