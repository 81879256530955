@import "variables";

ul.cart {
    padding: 0;
    width: 100%;

    li {
        display: flex;
        justify-content: space-between;
        padding: 5px 10px;
        flex-wrap: wrap;

        &:not(:last-child) {
            border-bottom: 1px solid white;
        }

        div {
            display: flex;
            align-items: center;

            p {
                margin: 0;
                margin-right: 10px;
            }
        }

        .removeFromCart {
            cursor: pointer;
            width: 25px;
            background-color: transparent;
            border: none;

            // &:before {
            //     content: "\f2ed";
            //     font-family: $awesome;
            //     font-size: 16px;
            //     color: white;
            //     margin-right: 10px;
            // }
        }
    }
}
