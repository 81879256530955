@import "variables";

.admin {
	.cart-button {
		display: none;
	}
}
.cart-button {
    position: fixed;
    background-color: $blue;
    border-bottom-right-radius: 25px;
    top: 0;
    left: 0;
    margin: 0;
    height: 100px;
    border: none;
    color: white;
    font-size: 16px;
    padding: 20px;
    transition: all 0.2s;
    transition-timing-function: ease;
    z-index: 10001;
    cursor: pointer;
	font-weight: bold;

    &.cart-icon:before {
        content: "\f291";
    }
    &.close-icon {
        transform: translateX(calc(100vw - 100%));

        &:before {
            content: "\f00d";
        }
    }
    &:before {
        font-family: $awesome;
        font-size: 16px;
        color: white;
    }
}
