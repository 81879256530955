@import "variables";

.ant-layout-sider-children {
    position: relative;
}

.sidebar {
    padding: 50px;
    padding-bottom: 100px;
    background-color: $blue;
    color: white;
    height: 100%;
    overflow-y: auto;

    .header {
        img {
            width: 100%;
            margin-bottom: 30px;
        }
    }

    h1 {
        display: flex;
        font-size: 1em;
        align-items: center;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 18px;
        margin: 0;
        color: white;

        .fas {
            margin-right: 5px;
        }
    }

    p {
        font-style: italic;
        font-size: 14px;
    }

    ul {
        padding: 0;
        width: 100%;

        li {
            display: flex;
            justify-content: space-between;
            padding: 5px 10px;
            flex-wrap: wrap;
            font-family: $font-standard;

            &:not(:last-child) {
                border-bottom: 1px solid white;
            }

            div {
                display: flex;
                align-items: center;

                p {
                    margin: 0;
                    margin-right: 10px;
                }
            }

            .removeFromCart {
                cursor: pointer;
                width: 25px;
                background-color: transparent;
                border: none;

                &:before {
                    content: "\f2ed";
                    font-family: $awesome;
                    font-size: 16px;
                    color: white;
                    margin-right: 10px;
                }
            }
        }
    }

    .signOut {
        display: flex;
        justify-content: flex-end;
        padding-top: 10px;

        button {
            background-color: transparent;
            color: white;
            border: none;
            cursor: pointer;

            &:hover {
                color: $green;
            }
        }
    }
}
